import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import { useSelector } from 'UI/common/hooks/redux-hooks'
import { CustomerRM } from 'UI/routers/RouterManager'
import {
    CreateWidgetDto,
    CreateWidgetResponse,
    WidgetType,
} from '../../../api/widgetsAPI.types'
import { createWidgetTC, getWidgetsTC } from '../../../model/widgetsReducer'
import { WIDGET_CREATION_INITIAL_PAYLOAD } from '../../../lib/constants'

export const useWidgetsList = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    const { i18n } = useTranslation()
    const { widget: widgetTranslation, buttons: buttonsTranslation } =
        i18n.getResourceBundle(i18n.language, 'common') as LanguageType

    const widgets = useSelector<WidgetType[]>((state) => state.widgets.widgets)

    useEffect(() => {
        dispatch(getWidgetsTC())
    }, [])

    const addWidgetHandler = () => {
        const customizedWidgetInitialPayload: CreateWidgetDto = {
            ...WIDGET_CREATION_INITIAL_PAYLOAD,
            title: widgetTranslation.fields.title,
        }

        dispatch(createWidgetTC(customizedWidgetInitialPayload) as any).then(
            (result: CreateWidgetResponse) => {
                history.push(CustomerRM.widgets.getUrl.edit(result.data.Id.id))
            }
        )
    }

    return {
        buttonsTranslation,
        addWidgetHandler,
        widgets,
        widgetTranslation,
    }
}
