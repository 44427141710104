import React from 'react'
import { Control, UseFormRegister } from 'react-hook-form'

import classes from '../../ui/widget-settings-form.module.scss'

import { LanguageType } from 'translations/ru/common'
import { InputField } from 'UI/common/elements/textFields/Input'
import { WidgetFormType } from '../../ui/widget-settings-form'

type WidgetSettingsBlockPropsType = {
    widget: LanguageType['widget']
    // form props
    register: UseFormRegister<WidgetFormType>
    errors: any
    setValue: (name: keyof WidgetFormType, value: any) => void
    control: Control<WidgetFormType>
}

export const WidgetAnalytics = (props: WidgetSettingsBlockPropsType) => {
    return (
        <>
            {/* Main title */}
            <div className="commonTopWrapper  commonTopWrapper--widgetBlockTitle">
                <div className="commonPageTitle">
                    {props.widget.analyticsBlockTitle}
                </div>
            </div>

            {/* Form */}
            <div className={classes.widgetBox}>
                {/* Google Analytics */}
                <div className={classes.fieldBox}>
                    <label className={classes.label}>Google Analytics</label>
                    <InputField
                        defaultValue={'UA-123123-1'}
                        register={props.register('analytics.googleAnalytics')}
                    />
                </div>

                {/* Facebook Pixel */}
                <div className={classes.fieldBox}>
                    <label className={classes.label}>Facebook Pixel</label>
                    <InputField
                        defaultValue={'Facebook Pixel'}
                        register={props.register('analytics.facebookPixel')}
                    />
                </div>

                {/* Vkontakte Pixel */}
                <div className={classes.fieldBox}>
                    <label className={classes.label}>Vkontakte Pixel</label>
                    <InputField
                        defaultValue={'Vkontakte Pixel'}
                        register={props.register('analytics.vkontaktePixel')}
                    />
                </div>

                {/* Yandex Metrika */}
                <div className={classes.fieldBox}>
                    <label className={classes.label}>Yandex Metrika</label>
                    <InputField
                        defaultValue={'561635617'}
                        register={props.register('analytics.yandexMetrika')}
                    />
                </div>
            </div>
        </>
    )
}
