import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { AdminRM, CreateRoute, CustomerRM } from './RouterManager'
import { TemplatesPage } from '../ManagerAdmin/AdminEditTemplate/templates/TemplatesPage'
import { Editor } from '../ManagerAdmin/AdminEditTemplate/editor/Editor'
import { AdminBeatsPromotion } from '../ManagerAdmin/AdminBeatsPromotion'
import { Subscribers } from '../CustomerAdmin/CustomerSubscribers'
import { CustomerBeatsCompilation } from '../CustomerAdmin/CustomerBeatsCompilation/CustomerBeatsCompilation'
import CustomerCoupons from '../CustomerAdmin/CustomerCoupons/CustomerCoupons'
import AdminShops from '../ManagerAdmin/AdminShopsCoupons/AdminShops'
import { AdminPayments } from '../ManagerAdmin/AdminPayments/AdminPayments'
import AdminCoupons from '../ManagerAdmin/AdminCoupons/AdminCoupons'
import { Orders } from '../ManagerAdmin/AdminPayments/Orders'
import { AdminBeats } from '../ManagerAdmin/AdminBeats/AdminBeats'
import { ExpertsPage } from '../ManagerAdmin/AdminExpertsCompilations/ExpertsPage'
import AdminSubscriptionsPage from '../ManagerAdmin/AdminSubscriptions/AdminSubscriptionsPage'
import ContractsLicensesPage from '../CustomerAdmin/CustomerBeatsContracts/ContractsLicensesPage'
import { CashbackPage } from '../CustomerAdmin/CustomerCashback/CashbackPage'
import { MyBeatsPage } from 'UI/CustomerAdmin/MyBeatsPage/MyBeatsPage'
import { BeatSettings } from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/beatSettings/BeatSettings'
import { MySales } from '../CustomerAdmin/CustomerMySales/MySales'
import { AppSettings } from 'settings/appSettings'
import { useSelector } from 'react-redux'
import { AppState } from 'BLL/Store'
import { CustomerCoinsPage } from '../CustomerAdmin/CustomerCoins/CustomerCoinsPage'
import { MySalesInformation } from '../CustomerAdmin/CustomerMySales/MySalesInformation/MySalesInformation'
import { BeatmakerSettings } from 'UI/CustomerAdmin/BeatmakerSettingsNew/beatmaker-settings/BeatmakerSettings'
import { CommonContentWrapper } from '../common/commonWrapper/CommonWrapper'
import { Discounts } from '../CustomerAdmin/CustomerDiscounts/Discounts'
import { Promotions } from '../CustomerAdmin/CustomerBeatsPromotion/Promotions'
import { Mobile } from '../CustomerAdmin/Mobile/Mobile'
import { Compilations } from '../CustomerAdmin/CustomerBeatsCompilation/compilations/Compilations'
import { BeatmakerProducerPage } from '../CustomerAdmin/BeatmakerProducerPage/BeatmakerProducerPage'
import { ShopAdmin } from '../ShopAdmin/ShopAdmin'
import { MobileBeatmakerSettings } from '../CustomerAdmin/Mobile/MobBeatmakerSettings/BeatmakerSettings'
import { MobDiscountsPage } from '../CustomerAdmin/Mobile/MobDiscountsPage/MobDiscountsPage'
import { CustomBrowserView } from '../common/browserView/CustomBrowserView'
import { CustomMobileView } from '../common/mobileView/CustomMobileView'
import { FormProviderWrapper } from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/beatSettings/forms/BeatSettingsFormContext'
import { FreeBeats } from 'UI/CustomerAdmin/FreeBeatsPage'
import { WidgetsListPage } from 'pages/widgets/WidgetsListPage'
import { WidgetSettingsPage } from 'pages/widgets/WidgetSettingsPage'

const CustomerStatistics = React.lazy(
    () => import('../../UI/CustomerAdmin/CustomerStatistics/CustomerStatistics')
)

export const Routers = () => {
    const userId = useSelector((state: AppState) => state.appReducer.userId)
    const adminRouters = [
        new CreateRoute(
            true,
            [AdminRM.templates.path.list, AdminRM.templates.path.delete],
            () => <TemplatesPage />
        ),
        new CreateRoute(false, [AdminRM.templates.path.edit], () => <Editor />),
        new CreateRoute(true, [AdminRM.promo.path.list], () => (
            <AdminBeatsPromotion />
        )),
        new CreateRoute(false, [AdminRM.shops.path.list], () => <AdminShops />),
        new CreateRoute(false, [AdminRM.payments.path.list], () => (
            <AdminPayments />
        )),
        new CreateRoute(false, [AdminRM.coupons.path.list], () => (
            <AdminCoupons />
        )),
        new CreateRoute(false, [AdminRM.orders.path.list], () => <Orders />),
        new CreateRoute(false, [AdminRM.beats.path.list], () => <AdminBeats />),
        new CreateRoute(false, [AdminRM.experts.path.list], () => (
            <ExpertsPage />
        )),
        new CreateRoute(true, [AdminRM.subscriptions.path.list], () => (
            <AdminSubscriptionsPage />
        )),
    ]
    const customerRouters = [
        new CreateRoute(true, [CustomerRM.statistics.path.list], () => (
            <CustomerStatistics />
        )),
        new CreateRoute(
            false,
            [CustomerRM.promotions.path.list],
            () => (
                <CommonContentWrapper>
                    <Promotions />
                </CommonContentWrapper>
            )
            // !AppSettings.api.isEngVersion()
        ),
        new CreateRoute(true, [CustomerRM.subscribers.path.list], () => (
            <CommonContentWrapper>
                <Subscribers />
            </CommonContentWrapper>
        )),
        new CreateRoute(
            true,
            [CustomerRM.compilations.path.list],
            () => (
                <CommonContentWrapper>
                    <CustomerBeatsCompilation />
                </CommonContentWrapper>
            )
            // !AppSettings.api.isEngVersion()
        ),
        new CreateRoute(
            true,
            [CustomerRM.compilations.path.create],
            () => (
                <CommonContentWrapper>
                    <Compilations />
                </CommonContentWrapper>
            )
            // !AppSettings.api.isEngVersion()
        ),
        new CreateRoute(false, [CustomerRM.shops.owner.path], () => (
            <CommonContentWrapper>
                <ShopAdmin />
            </CommonContentWrapper>
        )),
        new CreateRoute(false, [CustomerRM.shops.coupons.path], () => (
            <CommonContentWrapper>
                <CustomerCoupons />
            </CommonContentWrapper>
        )),
        new CreateRoute(
            true,
            [
                CustomerRM.contracts.path.license.delete,
                CustomerRM.contracts.path.license.base,
                CustomerRM.contracts.path.contracts.edit,
                CustomerRM.contracts.path.license.list,
                CustomerRM.contracts.path.license.edit,
            ],
            () => (
                <CommonContentWrapper>
                    <ContractsLicensesPage />
                </CommonContentWrapper>
            )
        ),
        new CreateRoute(false, [CustomerRM.settings.path.list], () => (
            <CommonContentWrapper>
                <CustomBrowserView>
                    <BeatmakerSettings />
                </CustomBrowserView>
                <CustomMobileView>
                    <MobileBeatmakerSettings />
                </CustomMobileView>
            </CommonContentWrapper>
        )),
        new CreateRoute(false, [CustomerRM.cashback.path.list], () => (
            <CommonContentWrapper>
                <CashbackPage />
            </CommonContentWrapper>
        )),
        new CreateRoute(
            true,
            [
                CustomerRM.myBeats.path.base,
                CustomerRM.myBeats.path.myBeats,
                CustomerRM.myBeats.path.myBeatsTab,
                CustomerRM.myBeats.path.myBeatsPublicationEdit,
                CustomerRM.myBeats.path.myBeatsPublicationDelete,
                CustomerRM.myBeats.path.myBeatsPublicationCancel,
                CustomerRM.myBeats.path.myBeatsArchiveRestore,
                CustomerRM.myBeats.path.myBeatsArchiveDelete,
            ],
            () => (
                <CommonContentWrapper>
                    <MyBeatsPage />
                </CommonContentWrapper>
            )
        ),
        new CreateRoute(false, [CustomerRM.myBeats.path.beats.edit], () => (
            <CommonContentWrapper>
                <FormProviderWrapper>
                    <BeatSettings />
                </FormProviderWrapper>
            </CommonContentWrapper>
        )),
        new CreateRoute(
            true,
            [
                CustomerRM.freeBeats.path.beats.list,
                CustomerRM.freeBeats.path.beats.edit,
                CustomerRM.freeBeats.path.beats.delete,
            ],
            () => (
                <CommonContentWrapper>
                    <FreeBeats />
                </CommonContentWrapper>
            )
        ),
        new CreateRoute(false, [CustomerRM.mySales.path.edit], () => (
            <CommonContentWrapper>
                <MySalesInformation />
            </CommonContentWrapper>
        )),
        new CreateRoute(false, [CustomerRM.mySales.path.list], () => (
            <CommonContentWrapper>
                <MySales />
            </CommonContentWrapper>
        )),
        new CreateRoute(false, [CustomerRM.myCoins.path.list], () => (
            <CommonContentWrapper>
                <CustomerCoinsPage />
            </CommonContentWrapper>
        )),
        new CreateRoute(false, [CustomerRM.discounts.path.list], () => (
            <CommonContentWrapper>
                <CustomBrowserView>
                    <Discounts />
                </CustomBrowserView>
                <CustomMobileView>
                    <MobDiscountsPage />
                </CustomMobileView>
            </CommonContentWrapper>
        )),
        new CreateRoute(false, [CustomerRM.producerPage.path], () => (
            <CommonContentWrapper>
                <BeatmakerProducerPage />
            </CommonContentWrapper>
        )),
        new CreateRoute(false, ['/mobile'], () => <Mobile />),
        new CreateRoute(
            true,
            [CustomerRM.widgets.path.create, CustomerRM.widgets.path.edit],
            () => (
                <CommonContentWrapper>
                    <WidgetSettingsPage />
                </CommonContentWrapper>
            )
        ),
        new CreateRoute(
            true,
            [CustomerRM.widgets.path.delete, CustomerRM.widgets.path.list],
            () => (
                <CommonContentWrapper>
                    <WidgetsListPage />
                </CommonContentWrapper>
            )
        ),
    ]

    return (
        <Switch>
            {!AppSettings.api.isEngVersion() &&
                adminRouters.map((r, index) => (
                    <Route
                        key={index}
                        exact={r.exact}
                        path={r.path}
                        render={r.render}
                    />
                ))}
            {customerRouters.map(
                (r, index) =>
                    r.isShow && (
                        <Route
                            key={index}
                            exact={r.exact}
                            path={r.path}
                            render={r.render}
                        />
                    )
            )}
            <Route
                path="/privacy-policy"
                component={() => {
                    AppSettings.api.isEngVersion()
                        ? (window.location.href =
                              'https://beatmakers.tv/help/promo')
                        : (window.location.href =
                              'https://beatmaker.tv/help/reklama_na_sajte')
                    return null
                }}
            />

            <Route
                path="/backToBeatmakerTV"
                component={() => {
                    window.location.href = `${AppSettings.urls.MAIN_DOMAIN}beatmaker/${userId}`
                    return null
                }}
            />
        </Switch>
    )
}
