import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import { useState } from 'react'
import { CustomerRM } from 'UI/routers/RouterManager'

export const useWidgetItem = () => {
    const history = useHistory()

    const { i18n } = useTranslation()
    const { buttons: buttonsTranslation } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    const [isOpenDeleteWidgetModal, setIsOpenDeleteWidgetModal] =
        useState(false)

    const cancelDeleteWidgetCallback = () => {
        history.push(CustomerRM.widgets.path.list)
        setIsOpenDeleteWidgetModal &&
            setIsOpenDeleteWidgetModal(!isOpenDeleteWidgetModal)
    }

    return {
        buttonsTranslation,
        cancelDeleteWidgetCallback,
        isOpenDeleteWidgetModal,
        setIsOpenDeleteWidgetModal,
    }
}
