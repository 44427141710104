import React, { ReactNode, useCallback, useEffect } from 'react'
import { useSelector } from '../hooks/redux-hooks'
import { RequestStatuses } from 'BLL/AppReducer'
import { PopUpCommon } from '../popUpCommon/PopUpCommon'
import { selectRequestStatus } from 'BLL/selectors/app-selectors'
import s from 'UI/common/modal/modal.module.scss'

type Props = {
    active: boolean
    setActive?: (value: boolean) => void
    headerDescription?: string
    title?: string
    cancelRedirect?: () => void
    children?: ReactNode
}

export const Modal = ({
    active,
    setActive,
    children,
    headerDescription,
    title,
    cancelRedirect,
}: Props) => {
    const requestStatuses = useSelector(selectRequestStatus)

    const handleKeyDown = useCallback(
        (event: KeyboardEvent) => {
            if (
                event.key === 'Escape' &&
                requestStatuses !== RequestStatuses.InProgress
            ) {
                setActive && setActive(false)
            }
        },
        [requestStatuses, setActive]
    )

    const handleClose = () => {
        if (cancelRedirect) {
            cancelRedirect()
        }
        if (requestStatuses !== RequestStatuses.InProgress) {
            setActive && setActive(false)
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown)
        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
    }, [requestStatuses, handleKeyDown])

    useEffect(() => {
        if (active) {
            document.body.style.overflow = 'hidden'
        }
        return () => {
            document.body.style.overflow = 'auto'
        }
    }, [active])

    return (
        <div className={active ? `${s.modal} ${s.active}` : s.modal}>
            <PopUpCommon
                headerDescription={headerDescription}
                title={title}
                handleClose={handleClose}
            >
                {children}
            </PopUpCommon>
        </div>
    )
}
