import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import cl from './WidgetBeatItem.module.scss'

import { CustomBrowserView } from 'UI/common/browserView/CustomBrowserView'
import { LanguageType } from 'translations/ru/common'
import { getLocaleDateString } from 'UI/utils/util-types'
import { CustomCheckbox } from 'UI/common/elements/CustomCheckbox'
import { WidgetBeatType } from 'features/widgets/api/widgetsAPI.types'

type WidgetPropsType = {
    beat: WidgetBeatType
    checkBeat: (beatId: number) => void
}

export const WidgetBeatItem: FC<WidgetPropsType> = ({ beat, checkBeat }) => {
    /* i18n */
    const { i18n } = useTranslation()
    const { widget } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    return (
        <CustomBrowserView>
            <div className={cl.box}>
                <div className={cl.checkbox}>
                    <CustomCheckbox
                        checked={beat.isSelected}
                        onChange={() => checkBeat(beat.id)}
                    />
                </div>

                <img
                    className={cl.img}
                    src={beat.images.thumbnail}
                    alt={`${beat.title} image`}
                />
                <div className={cl.beatInfo}>
                    <p className={cl.title}>{beat.title}</p>
                    {beat.addedToCatalogDate && (
                        <p className={cl.published}>
                            {`${
                                widget.popup.publishedDate
                            }: ${getLocaleDateString(
                                i18n.language,
                                beat.addedToCatalogDate
                            )}`}
                        </p>
                    )}
                </div>
            </div>
        </CustomBrowserView>
    )
}
