import { ThunkDispatch } from 'redux-thunk'
import { AppState, InferActionsTypes } from 'BLL/Store'
import { widgetsAPI } from 'features/widgets/api/widgetsAPI'
import {
    CreateWidgetDto,
    WidgetType,
} from 'features/widgets/api/widgetsAPI.types'
import { commonAsyncHandler, completeOperation } from 'BLL/common-async-handler'
import {
    actions as appActions,
    AuthActionsTypes,
    EditingEntityStatuses,
} from 'BLL/AppReducer'
import { ThunkActionType } from 'BLL/types'

const initState = {
    widgets: [] as WidgetType[],
    createdWidgetId: 0,
}

type StateType = typeof initState

type ActionsTypes = InferActionsTypes<typeof widgetsActions> | AuthActionsTypes

type ThunkDispatchType = ThunkDispatch<
    AppState,
    unknown,
    ActionsTypes | AuthActionsTypes
>

export const widgetsReducer = (
    state: StateType = initState,
    action: ActionsTypes
) => {
    switch (action.type) {
        case 'WIDGETS_REDUCER/GET_WIDGETS':
            return {
                ...state,
                widgets: action.widgets,
            }

        case 'WIDGETS_REDUCER/SET_CREATED_WIDGET_ID':
            return {
                ...state,
                createdWidgetId: action.id,
            }
        case 'WIDGETS_REDUCER/DELETE_WIDGET':
            return {
                ...state,
                widgets: state.widgets.filter((w) => w.id !== action.id),
            }

        default:
            return state
    }
}

// ACTIONS
export const widgetsActions = {
    getWidgets: (widgets: WidgetType[]) =>
        ({
            type: 'WIDGETS_REDUCER/GET_WIDGETS',
            widgets,
        } as const),
    deleteWidget: (id: number) =>
        ({
            type: 'WIDGETS_REDUCER/DELETE_WIDGET',
            id,
        } as const),
    setCreatedWidgetId: (id: number) => {
        return {
            type: 'WIDGETS_REDUCER/SET_CREATED_WIDGET_ID',
            id,
        } as const
    },
}

// THUNKS
export const getWidgetsTC =
    (): ThunkActionType<ActionsTypes> =>
    async (dispatch: ThunkDispatchType) => {
        await commonAsyncHandler(async () => {
            const res = await widgetsAPI.getWidgets()
            dispatch(widgetsActions.getWidgets(res.items))
            return res
        }, dispatch)
    }

export const createWidgetTC =
    (widget: CreateWidgetDto): ThunkActionType<ActionsTypes> =>
    async (dispatch: ThunkDispatchType) => {
        return await commonAsyncHandler(async () => {
            return await widgetsAPI.createWidget(widget)
        }, dispatch)
    }

export const deleteWidgetTC =
    (id: number): ThunkActionType<ActionsTypes> =>
    async (dispatch: ThunkDispatchType) => {
        return await commonAsyncHandler(async () => {
            const response = await widgetsAPI.deleteWidget(id)
            completeOperation(response, dispatch, () => {
                dispatch(widgetsActions.deleteWidget(id))
                dispatch(
                    appActions.setEditingEntityStatus(
                        EditingEntityStatuses.Success
                    )
                )
            })
            return response
        }, dispatch)
    }
