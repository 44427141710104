import React from 'react'
import { NavLink } from 'react-router-dom'
import Tooltip from '@material-ui/core/Tooltip'

import EditIcon from 'UI/assets/icons/edit.svg'
import DeleteIcon from 'UI/assets/icons/delete.svg'

import { CustomerRM } from 'UI/routers/RouterManager'

import { WidgetType } from '../../../api/widgetsAPI.types'
import { useWidgetItem } from './useWidgetItem'
import { DeleteWidgetModal } from '../deleteWidgetModal/DeleteWidgetModal'

import classes from './WidgetItem.module.scss'

type WidgetItemProps = {
    widget: WidgetType
}

export const WidgetItem = ({ widget }: WidgetItemProps) => {
    const {
        buttonsTranslation,
        cancelDeleteWidgetCallback,
        isOpenDeleteWidgetModal,
        setIsOpenDeleteWidgetModal,
    } = useWidgetItem()

    return (
        <div className={classes.box}>
            <p className={classes.title}>{widget.title}</p>

            <div>
                <Tooltip title={buttonsTranslation.EDIT}>
                    <NavLink to={CustomerRM.widgets.getUrl.edit(widget.id)}>
                        <img
                            src={EditIcon}
                            alt={'edit'}
                            className={classes.icon}
                        />
                    </NavLink>
                </Tooltip>

                <Tooltip title={buttonsTranslation.DELETE}>
                    <NavLink to={CustomerRM.widgets.getUrl.delete(widget.id)}>
                        <img
                            className={classes.icon}
                            src={DeleteIcon}
                            alt={'delete'}
                            onClick={() =>
                                setIsOpenDeleteWidgetModal(
                                    !isOpenDeleteWidgetModal
                                )
                            }
                        />
                    </NavLink>
                </Tooltip>
            </div>

            <DeleteWidgetModal
                cancelDeleteWidgetCallback={cancelDeleteWidgetCallback}
                isOpenDeleteWidgetModal={isOpenDeleteWidgetModal}
                setIsOpenDeleteWidgetModal={setIsOpenDeleteWidgetModal}
                widgetTitle={widget.title}
            />
        </div>
    )
}
