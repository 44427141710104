import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import cl from './WidgetAddBeatModal.module.scss'

import { Modal } from 'UI/common/modal/modal'
import { Button } from 'UI/common/elements/buttons/Button'
import { WidgetBeatItem } from '../WidgetBeatItem/WidgetBeatItem'
import { useSelector } from 'UI/common/hooks/redux-hooks'
import { MyBeatType } from 'DAL/BeatSettingsAPI'
import { LanguageType } from 'translations/ru/common'
import { getBeats } from 'BLL/CustomerMyBeatsReducer'
import classes from '../../../ui/widget-settings-form.module.scss'
import { CustomCheckbox } from 'UI/common/elements/CustomCheckbox'
import { SearchField } from 'UI/common/elements/textFields/search'
import { WidgetBeatType } from 'features/widgets/api/widgetsAPI.types'

type PropsType = {
    isAddBeatsModal: boolean
    setIsAddBeatsModal: (value: boolean) => void
}

export const WidgetAddBeatModal: FC<PropsType> = ({
    isAddBeatsModal,
    setIsAddBeatsModal,
}) => {
    const dispatch = useDispatch()

    /* i18n */
    const { i18n } = useTranslation()
    const { widget, buttons, helpMessage } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    const [widgetBeats, setWidgetBeats] = useState<WidgetBeatType[]>([])
    const [isChooseAllBeats, setIsChooseAllBeats] = useState(false)
    const [term, setTerm] = useState('')

    const beats = useSelector<MyBeatType[]>((state) => state.myBeatsPage.items)

    useEffect(() => {
        dispatch(getBeats())
    }, [])

    useEffect(() => {
        checkBeats(beats, false)
    }, [beats])

    // useEffect(() => {
    //     console.log('Term: ', term)
    // }, [term])

    const checkboxHandler = () => {
        checkBeats(widgetBeats, !isChooseAllBeats)
        setIsChooseAllBeats(!isChooseAllBeats)
    }

    const checkBeats = (
        beats: Array<WidgetBeatType | MyBeatType>,
        isSelected: boolean
    ) => {
        const res = beats.map((b) => ({ ...b, isSelected }))
        setWidgetBeats(res)
    }

    const checkBeat = (beatId: number) => {
        const arr = widgetBeats.map((b) => {
            return b.id === beatId ? { ...b, isSelected: !b.isSelected } : b
        })
        setWidgetBeats(arr)

        // Choose all beats if isSelected in everyone checked
        const value = arr.every((b) => b.isSelected)
        setIsChooseAllBeats(value)
    }

    const closeModalHandler = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault()
        setIsAddBeatsModal(false)
    }

    const saveBeatsHandler = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault()
        setIsAddBeatsModal(false)
        const beats = widgetBeats.filter((b) => b.isSelected)
        const beatsIds = beats.map((b) => b.id)
        alert(JSON.stringify(beatsIds, null, 2))
    }

    return (
        <Modal
            active={isAddBeatsModal}
            setActive={setIsAddBeatsModal}
            title={widget.popup.addBeatsTitle}
        >
            <div className={cl.searchBox}>
                <SearchField
                    placeholder={helpMessage.search}
                    value={term}
                    onChange={({ target: { value } }) => setTerm(value)}
                    customStyles={'lightSearch'}
                />
            </div>

            <div className={cl.checkBox}>
                <CustomCheckbox
                    checked={isChooseAllBeats}
                    onChange={checkboxHandler}
                />
                <label className={classes.checkBoxText}>
                    {widget.popup.chooseAll}
                </label>
            </div>

            <div className={cl.beatsBox}>
                {widgetBeats.map((b) => {
                    return (
                        <WidgetBeatItem
                            key={b.id}
                            beat={b}
                            checkBeat={checkBeat}
                        />
                    )
                })}
            </div>

            <div className={cl.btnBox}>
                <Button
                    view={'Secondary'}
                    title={buttons.CANCEL}
                    onClick={closeModalHandler}
                />
                <Button title={buttons.SAVE} onClick={saveBeatsHandler} />
            </div>
        </Modal>
    )
}
