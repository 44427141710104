import React from 'react'

import { useRedirectCustomHook } from 'UI/common/hooks/useRedirectCustomHook'
import { RolesTypeEnum } from 'BLL/enums'
import { Button } from 'UI/common/elements/buttons/Button'
import { CustomBrowserView } from 'UI/common/browserView/CustomBrowserView'

import { WidgetItem } from '../../local/widgetItem/WidgetItem'
import { useWidgetsList } from './useWidgetsList'
import styles from './WidgetsList.module.scss'

export const WidgetsList = React.memo(() => {
    const { addWidgetHandler, buttonsTranslation, widgets, widgetTranslation } =
        useWidgetsList()

    return (
        <>
            {useRedirectCustomHook(RolesTypeEnum.BEATMAKER)}
            <CustomBrowserView>
                <div className="commonTopWrapper">
                    <div className="commonPageTitle">
                        {widgetTranslation.mainPageTitle}
                    </div>

                    <div className="commonSearchWrapper">
                        <Button
                            title={buttonsTranslation.ADD_WIDGET}
                            onClick={addWidgetHandler}
                        />
                    </div>
                </div>
            </CustomBrowserView>

            {widgets.length ? (
                <div>
                    <div className={styles.tableHeader}>
                        {widgetTranslation.name}
                    </div>
                    {widgets.map((w) => (
                        <WidgetItem key={w.id} widget={w} />
                    ))}
                </div>
            ) : (
                <p>{widgetTranslation.notFound}</p>
            )}
        </>
    )
})
