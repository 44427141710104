import React from 'react'
import paypal from 'UI/assets/icons/payPal.svg'
import yomoney from 'UI/assets/icons/iomoney.svg'
import enot from 'UI/assets/icons/enot.svg'
import qiwi from 'UI/assets/icons/qiwi.svg'
import tinkoff from 'UI/assets/icons/tinkoff-pay.png'
import s from './walletPayVia.module.scss'

export type Wallets = 'PayPal' | 'QiwiP2P' | 'Tinkoff' // | 'YooMoney' | 'Enot'

type Props = {
    payMethod: Wallets
    handleChangeCurrentWallet: (wallet: Wallets) => void
    isActivated: boolean
    isSelected: boolean
} & React.ButtonHTMLAttributes<HTMLButtonElement>

export const WalletPayVia = ({
    payMethod,
    handleChangeCurrentWallet,
    isActivated,
    isSelected,
    ...rest
}: Props) => {
    const icon = {
        PayPal: paypal,
        YooMoney: yomoney,
        Enot: enot,
        QiwiP2P: qiwi,
        Tinkoff: tinkoff,
    }

    const selectedClass = isSelected ? `${s.button} ${s.isSelected}` : s.button

    return (
        <button
            className={selectedClass}
            {...rest}
            onClick={() => handleChangeCurrentWallet(payMethod)}
        >
            <img className={s.icon} src={icon[payMethod]} alt="payMethod" />
        </button>
    )
}
