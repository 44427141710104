import { widgetsAxios } from 'DAL/createAxios'
import {
    CreateWidgetDto,
    CreateWidgetResponse,
    DeleteWidgetResponse,
    GetWidgetByIdResponse,
    GetWidgetsResponse,
} from './widgetsAPI.types'

export const widgetsAPI = {
    getWidgets() {
        return widgetsAxios.get<GetWidgetsResponse>('').then((res) => res.data)
    },
    getWidgetById(id: number) {
        return widgetsAxios
            .get<GetWidgetByIdResponse>(`${id}`)
            .then((res) => res.data)
    },
    createWidget(widget: CreateWidgetDto) {
        return widgetsAxios
            .post<CreateWidgetResponse>('', widget)
            .then((res) => res.data)
    },
    deleteWidget(id: number) {
        return widgetsAxios
            .delete<DeleteWidgetResponse>(`${id}`)
            .then((res) => res.data)
    },
}
