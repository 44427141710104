import React from 'react'
import { Modal } from 'UI/common/modal/modal'
import { Button } from 'UI/common/elements/buttons/Button'

import styles from './DeleteWidgetModal.module.scss'
import { useDeleteWidgetModal } from './useDeleteWidgetModal'

type DeleteWidgetModalProps = {
    cancelDeleteWidgetCallback: () => void
    isOpenDeleteWidgetModal: boolean
    setIsOpenDeleteWidgetModal: (value: boolean) => void
    widgetTitle: string
}

export const DeleteWidgetModal = ({
    cancelDeleteWidgetCallback,
    isOpenDeleteWidgetModal,
    setIsOpenDeleteWidgetModal,
    widgetTitle,
}: DeleteWidgetModalProps) => {
    const {
        buttonsTranslation,
        deleteWidgetCancelRedirect,
        deleteWidgetHandler,
        widgetTranslation,
    } = useDeleteWidgetModal()

    return (
        <Modal
            active={isOpenDeleteWidgetModal}
            setActive={setIsOpenDeleteWidgetModal}
            title={widgetTranslation.popup.deleteTitle}
            cancelRedirect={deleteWidgetCancelRedirect}
        >
            <p className={styles.modalBodyDescription}>
                {widgetTranslation.popup.deleteMessage}
                <strong>{widgetTitle}</strong>
            </p>
            <div className={styles.modalButtonsBox}>
                <Button
                    view={'Secondary'}
                    title={buttonsTranslation.CANCEL}
                    onClick={cancelDeleteWidgetCallback}
                />
                <Button
                    view={'Delete'}
                    title={buttonsTranslation.DELETE}
                    onClick={deleteWidgetHandler}
                />
            </div>
        </Modal>
    )
}
