import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useSelector } from 'UI/common/hooks/redux-hooks'
import { NullableType } from 'BLL/types'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import { LanguageEnum, WidgetThemeType } from 'BLL/enums'
import { CreateWidgetDto } from 'features/widgets/api/widgetsAPI.types'
import { createWidgetTC } from 'features/widgets/model/widgetsReducer'
import { CustomerRM } from 'UI/routers/RouterManager'
import { AppSettings } from 'settings/appSettings'
import { LangSelectType } from 'translations/en/common'
import {
    useWidgetSettingsForm,
    WidgetFormType,
} from 'features/widgets/widget-settings-form/hooks/use-widget-settings-form'

export const useContainer = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    const [urlSettings, setUrlSettings] = useState<
        Pick<WidgetFormType, 'width' | 'height'>
    >({
        width: 1024,
        height: 800,
    })

    const beatmakerId = useSelector<NullableType<number>>(
        (state) => state.appReducer.userId
    )

    /* i18n */
    const { i18n } = useTranslation()
    const { widget, buttons, languageList } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    const { errors, control, handleSubmit, register, setValue, watch } =
        useWidgetSettingsForm()

    const onSubmit = handleSubmit(async (formValues: WidgetFormType) => {
        console.log(formValues)
        const data = {
            ...formValues,
            // Todo: Set appropriate lang value

            // lang: adaptLang(formValues.lang.value),

            // Todo: Get real colors
            color: {
                theme: WidgetThemeType.DarkNeon,
                styles: 'color: red',
            },
        }

        let testCreatingPayload: CreateWidgetDto = {
            title: 'Jinny',
            height: 600,
            width: 950,
            lang: 0,
            autoplay: false,
            isWidthResponsive: false,
        }

        await dispatch(createWidgetTC(testCreatingPayload))
        history.push(CustomerRM.widgets.path.list)
        setUrlSettings({ width: data.width, height: data.height })
    })

    useEffect(() => {
        const isEng = AppSettings.api.isEngVersion()

        setDefaultFormValue<boolean>('isWidthResponsive', false)
        setDefaultFormValue<boolean>('autoplay', false)
        setDefaultFormValue<LangSelectType>('lang', {
            value: isEng ? 1 : 2,
            label: isEng ? languageList.en : languageList.ru,
        })
        setDefaultFormValue<{ value: number; label: string }>('tracklist', {
            value: 1,
            label: widget.fields.selectAllBeats,
        })
    }, [])

    function setDefaultFormValue<T>(registerName: string, defaultValue: T) {
        setValue(registerName, defaultValue)
    }

    const adaptLang = (langValue: number) => {
        return langValue === 1 ? LanguageEnum.En : LanguageEnum.Ru
    }

    return {
        beatmakerId,
        buttons,
        control,
        errors,
        onSubmit,
        register,
        setValue,
        urlSettings,
        watch,
        widget,
    }
}
export type WidgetSettingsFormProps = ReturnType<typeof useContainer>
