import { useForm } from 'react-hook-form'

import { CreateWidgetDto } from 'features/widgets/api/widgetsAPI.types'

export type WidgetFormType = CreateWidgetDto

export const useWidgetSettingsForm = () => {
    const {
        register,
        handleSubmit,
        control,
        setValue,
        watch,
        formState: { errors },
    } = useForm<WidgetFormType>()

    return {
        control,
        errors,
        handleSubmit,
        register,
        setValue,
        watch,
    }
}
