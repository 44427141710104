import { applyMiddleware, combineReducers, createStore } from 'redux'
import customerBeatsPromotion from './CustomerBeatsPromotionReducer'
import customerStatistics from './CustomerStatisticsReducer'
import CustomerSubscribersReducer from './CustomerSubscribersReducer'
import AppReducer from './AppReducer'
import ShopAdminReducer from './ShopAdminReducer'
import thunk from 'redux-thunk'
import customerBeatPublicationsReducer from './CustomerBeatsPublicationReducer'
import CustomerBeatsCompilationReducer from './CustomerBeatsCompilationReducer'
import AdminExpertsCompilationReducer from './AdminExpertsCompilationReducer'
import AdminSubscriptionsReducer from './AdminSubscriptionsReducer'
import AdminTemplatesEmailReducer from './AdminTemplatesEmailReducer'
import CustomerCouponsReducer from './CustomerCouponsReducer'
import AdminShopsReducer from './AdminShopsReducer'
import { AdminPaymentsReducer } from './AdminPaymentsReducer'
import ContractsReducer from './ContractsReducer'
import BeatmakerSettingsReducer from './BeatmakerSettingsReducer'
import customerMyBeatsReducer from './CustomerMyBeatsReducer'
import MySalesReducer from './MySalesReducer'
import { coinsReducer } from './CustomerCoinsReducer'
import { walletsReducer } from './CustomerWalletsReducer'
import { CustomerDiscountsReducer } from './CustomerDiscountsReducer'
import { producerPageReducer } from './ProducerPageReducer'
import { widgetsReducer } from 'features/widgets/model/widgetsReducer'
import customerFreeBeatsReducer from './CustomerFreeBeatsReducer'
import customerArchiveBeatsReducer from './CustomerArchiveBeatsReducer'

export const rootReducer = combineReducers({
    customerCoupons: CustomerCouponsReducer,
    customerBeatPublications: customerBeatPublicationsReducer,
    customerBeatsPromotion: customerBeatsPromotion,
    customerStatistics: customerStatistics,
    shopAdmin: ShopAdminReducer,
    appReducer: AppReducer,
    customerSubscribers: CustomerSubscribersReducer,
    customerBeatsCompilation: CustomerBeatsCompilationReducer,
    adminExpertsCompilation: AdminExpertsCompilationReducer,
    adminSubscriptions: AdminSubscriptionsReducer,
    adminTemplatesEmail: AdminTemplatesEmailReducer,
    beatmakerContracts: ContractsReducer,
    managerShops: AdminShopsReducer,
    paymentsAdmin: AdminPaymentsReducer,
    beatmakerSetting: BeatmakerSettingsReducer,
    myBeatsPage: customerMyBeatsReducer,
    freeBeatsPage: customerFreeBeatsReducer,
    archiveBeatsPage: customerArchiveBeatsReducer,
    mySales: MySalesReducer,
    coinsReducer: coinsReducer,
    walletsReducer: walletsReducer,
    discounts: CustomerDiscountsReducer,
    producerPage: producerPageReducer,
    widgets: widgetsReducer,
})

const store = createStore(rootReducer, applyMiddleware(thunk))

//@ts-ignore
window.store = store

export type AppState = ReturnType<typeof rootReducer>

export type ActionTypes<T> = T extends { [key: string]: infer U } ? U : never
export type InferActionsTypes<
    T extends { [key: string]: (...args: any[]) => any }
> = ReturnType<ActionTypes<T>>

export default store
