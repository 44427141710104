import React, { ReactNode, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { UpdateBeat } from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/beatSettings/BeatSettings'
import * as yup from 'yup'
import { UseBeatSettingsSelectors } from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/beatSettings/hooks/useBeatSettingsSelectors'

export const schema = yup
    .object({
        name: yup.string().required(),
    })
    .required()

type Props = {
    children: ReactNode
}

export const FormProviderWrapper = ({ children }: Props) => {
    const { currentBeat, publications, moods, styles } =
        UseBeatSettingsSelectors()

    const publication = publications.find(
        (pub) => pub.beat.beatId === currentBeat?.beat.id
    )

    const style = styles.find((style) => style.id === currentBeat?.beat.styleId)
    const mood = moods.find((mood) => mood.id === currentBeat?.beat.moodId)

    const methods = useForm<UpdateBeat>({
        // @ts-ignore
        resolver: yupResolver(schema),
    })

    useEffect(() => {
        if (currentBeat) {
            methods.reset({
                name: currentBeat.beat.name,
                description: currentBeat.beat.description,
                tempo: currentBeat.beat.tempo,
                playUrl: currentBeat.contextBeat.playUrl,
                publicationDate: publication?.date,
                publicationTime: publication?.date,
                styleId: {
                    value: currentBeat?.beat.styleId,
                    label: style ? style.title : 'Select...',
                },
                moodId: {
                    value: currentBeat?.beat.moodId,
                    label: mood ? mood.title : 'Select...',
                },
            })
        }
    }, [currentBeat, methods.reset, style, mood])

    return <FormProvider {...methods}>{children}</FormProvider>
}
