const adminPrefix = '/admin'
const AdminRouterManager = {
    templates: {
        path: {
            list: `${adminPrefix}/templates`,
            delete: `${adminPrefix}/templates/delete/:id`,
            edit: `${adminPrefix}/templates/edit/:id`,
        },
        getUrl: {
            delete(id: string | number) {
                return `${adminPrefix}/templates/delete/${id}`
            },
            edit(id: string | number) {
                return `${adminPrefix}/templates/edit/${id}`
            },
        },
    },
    promo: {
        path: {
            list: `${adminPrefix}/promo`,
        },
    },
    payments: {
        path: {
            list: `${adminPrefix}/payments`,
        },
        getUrl: {
            queryByBeatmakerId(id: string | number) {
                return `${adminPrefix}/payments?beatmakerId=${id}`
            },
        },
    },
    coupons: {
        path: {
            list: `${adminPrefix}/coupons`,
        },
    },
    orders: {
        path: {
            list: `${adminPrefix}/orders`,
        },
    },
    beats: {
        path: {
            list: `${adminPrefix}/beats`,
        },
    },
    experts: {
        path: {
            list: `${adminPrefix}/expertsCompilations`,
            edit: `${adminPrefix}/expertsCompilations/:id`,
        },
        getUrl: {
            edit(id: string | number) {
                return `${adminPrefix}/expertsCompilations/${id}`
            },
        },
    },
    subscriptions: {
        path: {
            list: `${adminPrefix}/subscriptions`,
        },
    },
    shops: {
        path: {
            list: `${adminPrefix}/shops`,
        },
    },
}

const customerPrefix = ''
const CustomerRouterManager = {
    statistics: {
        path: {
            list: `${customerPrefix}/customerStatistics`,
        },
    },
    promotions: {
        path: {
            list: `${customerPrefix}/beats-promotion`,
            edit: `${customerPrefix}/beats-promotion/edit/:id`,
            create: `${customerPrefix}/beats-promotion/create`,
        },
        getUrl: {
            edit(id: string | number) {
                return `${customerPrefix}/beats-promotion/edit/${id}`
            },
            create() {
                return `${customerPrefix}/beats-promotion/create`
            },
        },
    },
    subscribers: {
        path: {
            list: `${customerPrefix}/subscribers`,
        },
    },
    compilations: {
        path: {
            list: `${customerPrefix}/compilations`,
            create: `${customerPrefix}/compilations/create`,
        },
    },
    contracts: {
        path: {
            license: {
                base: `${customerPrefix}/contracts`,
                list: `${customerPrefix}/contracts/list`,
                edit: `${customerPrefix}/contracts/edit/:id?`,
                delete: `${customerPrefix}/contracts/delete/:licenseId`,
            },
            contracts: {
                edit: `${customerPrefix}/contracts/edit/:id/:lang`,
            },
        },
        getUrl: {
            license: {
                edit(id: string | number) {
                    return `${customerPrefix}/contracts/edit/${id}`
                },
                create() {
                    return `${customerPrefix}/contracts/edit`
                },
                delete(id: string | number) {
                    return `${customerPrefix}/contracts/delete/${id}`
                },
            },
            contracts: {
                edit(id: string | number, lang: string) {
                    return `${customerPrefix}/contracts/edit/${id}/${lang}`
                },
            },
        },
    },
    widgets: {
        path: {
            list: `${customerPrefix}/widgets`,
            create: `${customerPrefix}/widgets/create`,
            edit: `${customerPrefix}/widgets/edit/:widgetId?`,
            delete: `${customerPrefix}/widgets/delete/:widgetId`,
        },
        getUrl: {
            create() {
                return `${customerPrefix}/widgets/create`
            },
            edit(id: number) {
                return `${customerPrefix}/widgets/edit/${id}`
            },
            delete(id: number) {
                return `${customerPrefix}/widgets/delete/${id}`
            },
        },
    },
    settings: {
        path: {
            list: `${customerPrefix}/settings/:tab?`,
            getUrl(tab: string) {
                return `${customerPrefix}/settings/${tab}`
            },
            wallets: {
                base: `${customerPrefix}/settings/wallets`,
                delete(cardId: string) {
                    return `${customerPrefix}/settings/wallets/${cardId}/delete`
                },
            },
        },
    },
    cashback: {
        path: {
            list: `${customerPrefix}/cashbacks`,
        },
    },
    myBeats: {
        path: {
            base: `${customerPrefix}/`,
            myBeats: `${customerPrefix}/my-beats/`,
            myBeatsTab: `${customerPrefix}/my-beats/:tab`,
            myBeatsPublicationEdit: `${customerPrefix}/my-beats/:tab/publications/:id/edit`,
            myBeatsPublicationDelete: `${customerPrefix}/my-beats/:tab/publications/:id/delete`,
            myBeatsPublicationCancel: `${customerPrefix}/my-beats/:tab/publications/:id/cancel`,
            myBeatsArchiveRestore: `${customerPrefix}/my-beats/:tab/:id/restore`,
            myBeatsArchiveDelete: `${customerPrefix}/my-beats/:tab/:id/delete`,
            publications: {
                edit: `${customerPrefix}/my-beats/paid/publications/:beatId/edit`,
                delete: `${customerPrefix}/my-beats/paid/publications/:beatId/delete`,
                cancelPublications: `${customerPrefix}/my-beats/paid/publications/:publicationId`,
                cancel: `${customerPrefix}/my-beats/paid/publications/:beatId/cancel`,
            },
            beats: {
                base: `${customerPrefix}/my-beats/paid`,
                list: `${customerPrefix}/my-beats/paid`,
                edit: `${customerPrefix}/my-beats/paid/edit/:beatId`,
            },
            archive: {
                base: `${customerPrefix}/my-beats/archive`,
                delete: `${customerPrefix}/my-beats/archive/:beatId/delete`,
                restore: `${customerPrefix}/my-beats/archive/:beatId/restore`,
            },
        },
        getUrl: {
            beats: {
                edit(id: string | number) {
                    return `${customerPrefix}/my-beats/paid/edit/${id}`
                },
            },
            publications: {
                edit(id: string | number) {
                    return `${customerPrefix}/my-beats/paid/publications/${id}/edit`
                },
                cancelPublications(id: string | number) {
                    return `${customerPrefix}/my-beats/paid/publications/${id}`
                },
                delete(id: string | number) {
                    return `${customerPrefix}/my-beats/paid/publications/${id}/delete`
                },
                cancel(id: string | number) {
                    return `${customerPrefix}/my-beats/paid/publications/${id}/cancel`
                },
            },
            archiveBeats: {
                restore(id: string | number) {
                    return `${customerPrefix}/my-beats/archive/${id}/restore`
                },
                delete(id: string | number) {
                    return `${customerPrefix}/my-beats/archive/${id}/delete`
                },
            },
        },
    },
    freeBeats: {
        path: {
            beats: {
                base: `${customerPrefix}/free-beats`,
                list: `${customerPrefix}/free-beats`,
                edit: `${customerPrefix}/free-beats/:freeBeatId/edit`,
                delete: `${customerPrefix}/free-beats/:freeBeatId/delete`,
            },
        },
        getUrl: {
            beats: {
                delete(id: string | number) {
                    return `${customerPrefix}/free-beats/${id}/delete`
                },
                edit(id: string | number) {
                    return `${customerPrefix}/free-beats/${id}/edit`
                },
            },
        },
    },
    mySales: {
        path: {
            edit: `${customerPrefix}/my-sales/details/:id`,
            list: `${customerPrefix}/my-sales`,
            confirm: `${customerPrefix}/my-sales/confirm/:id`,
            withdrawal: `${customerPrefix}/my-sales/withdrawal`,
        },
        getUrl: {
            show(id: string | number) {
                return `${customerPrefix}/my-sales/details/${id}`
            },
            confirm(id: string | number) {
                return `${customerPrefix}/my-sales/confirm/${id}`
            },
        },
    },
    myCoins: {
        path: {
            list: `${customerPrefix}/coins`,
        },
    },
    discounts: {
        path: {
            list: `${customerPrefix}/discounts/:tab?`,
            getUrl(tab: string) {
                return `${customerPrefix}/discounts/${tab}`
            },
        },
        edit: {
            path: `${customerPrefix}/discounts/coupons/:couponId`,
            getUrl(couponId: string) {
                return `${customerPrefix}/discounts/coupons/${couponId}`
            },
        },
        deleteCoupon: {
            path: `${customerPrefix}/discounts/coupons/delete/:discountId`,
            getUrl(discountId: string) {
                return `${customerPrefix}/discounts/coupons/delete/${discountId}`
            },
        },
        create: {
            path: `${customerPrefix}/discounts/group-discounts/create`,
        },
        delete: {
            path: `${customerPrefix}/discounts/group-discounts/delete/:discountId`,
            getUrl(discountId: string) {
                return `${customerPrefix}/discounts/group-discounts/delete/${discountId}`
            },
        },
    },
    producerPage: {
        path: `${customerPrefix}/producer-page`,
    },
    shops: {
        coupons: {
            path: `${customerPrefix}/shop/customer`,
            getUrl(shopId: string | number) {
                return `${customerPrefix}/shop/customer?shopId=${shopId}`
            },
            delete: {
                path: `${customerPrefix}/shop/customer/coupons/delete/:couponId`,
                getUrl(couponId: string | number) {
                    return `${customerPrefix}/shop/customer/coupons/delete/${couponId}`
                },
            },
            edit: {
                path: `${customerPrefix}/shop/customer/coupons/edit/:couponId`,
                getUrl(couponId: string | number) {
                    return `${customerPrefix}/shop/customer/coupons/edit/${couponId}`
                },
            },
        },
        owner: {
            path: `${customerPrefix}/shop-owner/admin`,
            edit: {
                path: `${customerPrefix}/shop-owner/admin/coupons/edit/:couponId`,
                getUrl(couponId: string | number) {
                    return `${customerPrefix}/shop-owner/admin/coupons/edit/${couponId}`
                },
            },
        },
    },
}
export const AdminRM = AdminRouterManager
export const CustomerRM = CustomerRouterManager

export class CreateRoute {
    constructor(
        public exact: boolean,
        public path: string[],
        public render: any,
        public isShow = true
    ) {}
}
