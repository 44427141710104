// @ts-ignore
import { useParams } from 'react-router'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { LanguageType } from 'translations/ru/common'
import { CustomerRM } from 'UI/routers/RouterManager'

import { deleteWidgetTC } from '../../../model/widgetsReducer'

export const useDeleteWidgetModal = () => {
    const { widgetId } = useParams()

    const dispatch = useDispatch()
    const history = useHistory()

    const { i18n } = useTranslation()
    const { widget: widgetTranslation, buttons: buttonsTranslation } =
        i18n.getResourceBundle(i18n.language, 'common') as LanguageType

    const deleteWidgetCancelRedirect = () =>
        history.push(CustomerRM.widgets.path.list)

    const deleteWidgetHandler = () => {
        dispatch(deleteWidgetTC(Number(widgetId)))
    }

    return {
        buttonsTranslation,
        deleteWidgetCancelRedirect,
        deleteWidgetHandler,
        widgetTranslation,
    }
}
