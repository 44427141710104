import { AppSettings } from 'settings/appSettings'
import { CreateWidgetDto } from '../../api/widgetsAPI.types'

export const WidgetLangMap = {
    Ru: 0,
    En: 1,
} as const

export const DEFAULT_WIDGET_LANG =
    AppSettings.api.LANGUAGE_VERSION === 'ru'
        ? WidgetLangMap.Ru
        : WidgetLangMap.En

const DEFAULT_WIDGET_TITLE = 'WIDGET TITLE'

export const DEFAULT_WIDGET_HEIGHT = 600

export const DEFAULT_WIDGET_WIDTH = 800

export const DEFAULT_IS_WIDGET_WIDTH_RESPONSIVE = false

export const DEFAULT_WIDGET_AUTOPLAY = false

export const WIDGET_CREATION_INITIAL_PAYLOAD: CreateWidgetDto = {
    title: DEFAULT_WIDGET_TITLE,
    width: DEFAULT_WIDGET_WIDTH,
    height: DEFAULT_WIDGET_HEIGHT,
    isWidthResponsive: DEFAULT_IS_WIDGET_WIDTH_RESPONSIVE,
    lang: DEFAULT_WIDGET_LANG,
    autoplay: DEFAULT_WIDGET_AUTOPLAY,
}
