import React from 'react'
import { NavLink } from 'react-router-dom'

import { CustomerRM } from 'UI/routers/RouterManager'
import ArrowLeft from 'UI/assets/icons/arrow-left.svg'
import { useRedirectCustomHook } from 'UI/common/hooks/useRedirectCustomHook'
import { CustomBrowserView } from 'UI/common/browserView/CustomBrowserView'
import { Button } from 'UI/common/elements/buttons/Button'
import { RolesTypeEnum } from 'BLL/enums'
import { GeneralWidgetSettings } from '../ui-blocks/general-widget-settings'
import { WidgetPreview } from '../ui-blocks/widget-preview'
import { WidgetAnalytics } from '../ui-blocks/widget-analytics'
import { WidgetEmbedAndUrl } from '../ui-blocks/widget-embed-and-url'
import { WidgetSettingsFormProps } from 'features/widgets/widget-settings-form/container/use-container'

import classes from 'features/widgets/widget-settings-form/ui/widget-settings-form.module.scss'

export const WidgetSettingsForm = ({
    beatmakerId,
    buttons,
    control,
    errors,
    onSubmit,
    register,
    setValue,
    urlSettings,
    watch,
    widget,
}: WidgetSettingsFormProps) => {
    return (
        <>
            {useRedirectCustomHook(RolesTypeEnum.BEATMAKER)}
            <CustomBrowserView>
                <div className="commonArrowBack">
                    <NavLink to={CustomerRM.widgets.path.list}>
                        <img src={ArrowLeft} alt={'back-items'} />
                        <span>{widget.backToMainPage}</span>
                    </NavLink>
                </div>

                <form onSubmit={onSubmit}>
                    <GeneralWidgetSettings
                        errors={errors}
                        register={register}
                        control={control}
                        setValue={setValue}
                        watch={watch}
                    />

                    <WidgetAnalytics
                        widget={widget}
                        errors={errors}
                        register={register}
                        control={control}
                        setValue={setValue}
                    />

                    <WidgetPreview beatmakerId={beatmakerId} />

                    <WidgetEmbedAndUrl urlSettings={urlSettings} />

                    {/* Buttons */}
                    <div className={classes.wrapperButtonFooter}>
                        <NavLink to={CustomerRM.widgets.path.list}>
                            <Button view={'Secondary'} title={buttons.CANCEL} />
                        </NavLink>
                        <Button title={buttons.SAVE} />
                    </div>
                </form>
            </CustomBrowserView>
        </>
    )
}
